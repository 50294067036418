<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="siteId > 0">Site Edit</th>
              <th scope="col" v-else>Site Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Department" label-for="department">
              <b-form-select id="department" v-model="siteValue.department_id" :options="departments" required></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Site" label-for="title">
              <b-form-input required id="title" type="text" v-model="siteValue.title"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Start AM Time" label-for="start_am_time">
              <flat-pickr v-model="siteValue.start_am_time" :config="startAmPickerConfig" class="form-control" required></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="End AM Time" label-for="end_am_time">
              <flat-pickr v-model="siteValue.end_am_time" :config="endAmPickerConfig" class="form-control" required></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Start PM Time" label-for="start_pm_time">
              <flat-pickr v-model="siteValue.start_pm_time" :config="startPmPickerConfig" class="form-control" required></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="End PM Time" label-for="end_pm_time">
              <flat-pickr v-model="siteValue.end_pm_time" :config="endPmPickerConfig" class="form-control" required></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="12">
            <b-button v-if="siteId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save
            </b-button>
            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    vSelect,
    ToastificationContent,
    AppColorPaletteVue,
    flatPickr,
  },

  setup() {
    const SITE_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SITE_APP_STORE_MODULE_NAME)) store.registerModule(SITE_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP_STORE_MODULE_NAME)) store.unregisterModule(SITE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      siteValue: {
        title: '',
        department_id: '',
        start_am_time: '08:00',
        end_am_time: '12:00',
        start_pm_time: '13:00',
        end_pm_time: '17:00',
      },
      startAmPickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      },
      endAmPickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      },
      startPmPickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      },
      endPmPickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      },
      departments: [],
      loading: false,
      siteId: null,
      selectedDepartment: null,
    };
  },

  watch: {},

  mounted() {
    this.getSiteById();
  },

  watch: {
    'siteValue.start_am_time'(newTime) {
      if (newTime) {
        this.endAmPickerConfig.minTime = newTime;
        if (newTime > this.siteValue.end_am_time) {
          this.siteValue.end_am_time = newTime;
        }
      }
    },
    'siteValue.start_pm_time'(newTime) {
      if (newTime) {
        this.endPmPickerConfig.minTime = newTime;
        if (newTime > this.siteValue.end_pm_time) {
          this.siteValue.end_pm_time = newTime;
        }
      }
    },
  },

  methods: {
    getSiteById() {
      this.loading = true;

      if (router.currentRoute.params.id) {
        this.siteId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('settings/getSiteById', { id: router.currentRoute.params.id })
          .then((res) => {
            this.siteValue = res.data;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
      this.getDepartments();
    },

    getDepartments() {
      store
        .dispatch('settings/getAllDepartments')
        .then((res) => {
          this.departments = res.data.map((department) => ({
            value: department.id,
            text: department.title,
          }));

          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: 'settings' });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    formSubmitted() {
      this.loading = true;
      if (!this.siteValue.title || !this.siteValue.department_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please fill in all fields.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.loading = false;
        return;
      }

      if (this.siteId == null) {
        store
          .dispatch('settings/saveSite', this.siteValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Site Add Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-sites' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        store
          .dispatch('settings/updateSite', this.siteValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Site Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-sites' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
